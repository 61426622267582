import { Injectable } from "@angular/core";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import { Observable } from "rxjs";
import { KnoxApiBaseService } from "./knox-api-base.service";
import {
	IKnoxChangePasswordRequestDto,
	IKnoxDisableTwoFactorAuthenticationRequestDto,
	IKnoxEnableTwoFactorAuthenticationRequestDto,
	IKnoxEnableTwoFactorAuthenticationResponseDto,
	IKnoxMfaSecretResponseDto,
	ILoginRequestDto,
	ILoginResponseDto,
	ISendTwoFactorAuthenticationMessageRequestDto,
	ISendTwoFactorMessageResponseDto,
	ITwoFactorLoginRequestDto,
	ITwoFactorMethodDto,
	IdentityProviderLoginResponseDto,
	IOAuthSettingsDto,
} from "../interfaces/knox-auth";
import { KnoxAuthApiUrls, KnoxApiUrls } from "../helpers/knox-constants";
import {KnoxApi} from "@aex/shared/apis";
import {AuthType, ParamMetaData} from "@aex/shared/common-lib";
import {BaseConfigService} from "@aex/shared/root-services";

@Injectable({
	providedIn: 'root',
})
export class KnoxAuthApiService extends KnoxApiBaseService {
	constructor(
		http: HttpClient,
		private readonly configService: BaseConfigService,
	) {
    super(http);
  }

	private getOperatorHeaders(): HttpHeaders{
		console.log('getOperatorHeaders', this.configService.operator);
		const operatorHeaderId = this.configService.operator.toUpperCase();

		return new HttpHeaders()
			.set('X-OPERATOR-ID', operatorHeaderId)
			.set('X-ORG-UNIT-ID', operatorHeaderId);
	}

	public login(request: ILoginRequestDto ): Observable<ILoginResponseDto> {
		return this.post<ILoginRequestDto, ILoginResponseDto>(
			`${ KnoxAuthApiUrls.Auth }/${ KnoxAuthApiUrls.Login }`,
			request,
  		this.getOperatorHeaders(),
		);
	}

	public forgotPassword(loginId: string, resetUri:string = ''): Observable<string> {
		return this.post<string, string>(
			`${ KnoxAuthApiUrls.Auth }/${ KnoxAuthApiUrls.ForgotPassword }/${ loginId }?resetUri=${ resetUri }`,
			'',
			this.getOperatorHeaders(),
		);
	}

	public resetPassword(requestDto: IKnoxChangePasswordRequestDto): Observable<boolean> {
		return this.post<IKnoxChangePasswordRequestDto, boolean>(`${ KnoxAuthApiUrls.Auth }/${ KnoxAuthApiUrls.ResetPassword }`, requestDto);
	}

	public changePassword(requestDto: IKnoxChangePasswordRequestDto): Observable<boolean> {
		return this.post<IKnoxChangePasswordRequestDto, boolean>(`${ KnoxAuthApiUrls.Auth }/${ KnoxAuthApiUrls.ChangePassword }`, requestDto);
	}

	public initiateIdentityProviderLogin(identityProviderType: string, redirectUri: string): Observable<IdentityProviderLoginResponseDto> {
		return this.get<IdentityProviderLoginResponseDto>(
			`${ KnoxAuthApiUrls.Auth }/${ KnoxAuthApiUrls.IdentityProvider }/initiate?identityProviderLoginType=${ identityProviderType }&redirectUri=${redirectUri}`,
		);
	}

	public completeIdentityProviderLogin(identityProviderType: string, authorisationCode: string, redirectUri: string, identity_token: string): Observable<ILoginResponseDto> {
		const url =  'auth/identity-provider/token';
		const queryParams = `identityProviderLoginType=${ identityProviderType }&redirectUri=${redirectUri}&authorisationCode=${authorisationCode}&identityToken=${identity_token}`;
		return this.http.get<ILoginResponseDto>(
			KnoxApi.knoxUrlGeneral(`${url}?${queryParams}`),
			{
				headers: this.getOperatorHeaders(),
				params: new ParamMetaData({
					handleError: 'knox',
					authToken: AuthType.NONE,
				}),
			},
		);
	}
	public getIdentityProviderSettings(identityProviderType: string, redirectUri: string): Observable<IOAuthSettingsDto> {
		return this.get<IOAuthSettingsDto>(
			`${ KnoxAuthApiUrls.Auth }/${ KnoxAuthApiUrls.IdentityProvider }/settings?identityProviderLoginType=${ identityProviderType }&redirectUri=${redirectUri}`,
		);
	}

	/* Two Factor Authentication */
	public enableTwoFactorAuthentication(requestDto: IKnoxEnableTwoFactorAuthenticationRequestDto): Observable<IKnoxEnableTwoFactorAuthenticationResponseDto> {
		return this.post<IKnoxEnableTwoFactorAuthenticationRequestDto, IKnoxEnableTwoFactorAuthenticationResponseDto>(
  		`${KnoxAuthApiUrls.Auth}/${KnoxAuthApiUrls.TwoFactor}`,
			requestDto,
			this.getOperatorHeaders()
		);
	}

	public disableTwoFactorAuthentication(requestDto: IKnoxDisableTwoFactorAuthenticationRequestDto): Observable<boolean> {
		return this.post<IKnoxDisableTwoFactorAuthenticationRequestDto, boolean>(
			`${KnoxAuthApiUrls.Auth}/${KnoxAuthApiUrls.TwoFactor}/disable`,
  		requestDto,
			this.getOperatorHeaders()
		);
	}
	public sendTwoFactorAuthenticationMessage(requestDto: ISendTwoFactorAuthenticationMessageRequestDto): Observable<ISendTwoFactorMessageResponseDto> {
		return this.post<ISendTwoFactorAuthenticationMessageRequestDto, ISendTwoFactorMessageResponseDto>(
				`${KnoxAuthApiUrls.Auth}/${KnoxAuthApiUrls.TwoFactor}/send`,
				requestDto,
		);
	}

	public sendTwoFactorLoginMessage(twoFactorId:string, requestDto: ISendTwoFactorAuthenticationMessageRequestDto): Observable<ISendTwoFactorMessageResponseDto> {
		return this.post<ISendTwoFactorAuthenticationMessageRequestDto, ISendTwoFactorMessageResponseDto>(
				`${KnoxAuthApiUrls.Auth}/${KnoxAuthApiUrls.TwoFactor}/send/${twoFactorId}`,
				requestDto,
				this.getOperatorHeaders()
		);
	}

	public getMultiFactorSecret(): Observable<IKnoxMfaSecretResponseDto> {
		return this.get<IKnoxMfaSecretResponseDto>(
			`${KnoxAuthApiUrls.Auth}/${KnoxAuthApiUrls.MultiFactorSecret}`,
		);
	}

	public getUserTwoFactorMethods(userLoginId: string): Observable<ITwoFactorMethodDto[]> {
		return this.get<ITwoFactorMethodDto[]>(
				`${KnoxAuthApiUrls.Auth}/${KnoxApiUrls.Users}/by-login-id/${userLoginId}/two-factor-methods`,
		);
	}

	public twoFactorLogin(request: ITwoFactorLoginRequestDto): Observable<ILoginResponseDto>{
			return this.post<ITwoFactorLoginRequestDto, ILoginResponseDto>(`${ KnoxAuthApiUrls.Auth }/${ KnoxAuthApiUrls.Login }/two-factor`, request);
	}

}
