import { NavigationService, StorageService } from '@aex/ngx-toolbox';
import { ENVIRONMENT_CONFIG_TOKEN, IEnvironment } from '@aex/shared/common-lib';
import { BaseConfigService } from '@aex/shared/root-services';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {SECURITY_ROUTES} from "@aex/security/shared";

@Injectable()
export class AdminNexusConfigService extends BaseConfigService {
	constructor(
		http: HttpClient,
		navigationService: NavigationService,
		storageService: StorageService,
		@Inject(ENVIRONMENT_CONFIG_TOKEN) environment: IEnvironment,
	) {
		super(http, navigationService, storageService, environment);
	}

	protected override getHomeUrl(): string{
		return SECURITY_ROUTES.securityDashboard.name;
	}

	protected override getEnableSocialLogin(): boolean{
		return true;
	}

}
