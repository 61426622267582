import { appRoute } from '@aex/ngx-toolbox';

export const SECURITY_ROUTES = {
	login: appRoute('login'),
	twoFactorLogin: appRoute('two-factor-login'),
	twoFactorInit: appRoute('two-factor-init'),
	forgotPasswordCheckMail: appRoute('forgot-password-check-email'),
	knox: appRoute('knox'),
	register: appRoute('register'),
	rolesAndPermissions: appRoute('roles-and-permissions'),
	systemRoles: appRoute('system-roles'),
	manageSystemRole: appRoute('manage-system-role'),
	forgotPassword: appRoute('forgot-password'),
	securityDashboard: appRoute('security-dashboard'),
	changePassword: appRoute('change-password'),
	companyTypes: appRoute('company-types'),
	companyRoles: appRoute('company-roles'),
	companyCustomerRoles: appRoute('company-customer-roles'),
	companyTypesRoles: appRoute('company-type-roles'),
	companyAssociation: appRoute('company-association'),
	companyInheritedAccess: appRoute('company-inherited-access'),
	manageCompanyAssociationAsAccessor: appRoute('manage-company-association-as-accessor'),
	manageCompanyAssociationAsAccessed: appRoute('manage-company-association-as-accessed'),
	manageCompanyInheritedAccess: appRoute('manage-company-inherited-access'),
	manageCompanyAssociationRoles: appRoute('manage-company-association-roles'),
	cloneRoles: appRoute('clone-roles'),
	manageCompanyRoles: appRoute('manage-company-role'),
	manageCompanyCustomerRoles: appRoute('manage-company-customer-role'),
	manageUsers: appRoute('manage-users'),
	userProfile: appRoute('user-profile'),
	manageRoleUsers: appRoute('manage-role-users'),
	manageUserRoles: appRoute('manage-user-roles'),
	manageUserCompanyAssociations: appRoute('manage-user-company-associations'),
	roles: appRoute('roles'),
	createCompanyRole: appRoute('create-company-role'),
	newRole: appRoute('new-role'),
	rolesUsers: appRoute('roles-users'),
	users: appRoute('users'),
	user: appRoute('user'),
	createUser: appRoute('create-user'),
	usersRoles: appRoute('manage-user-roles'),
	associationRoles: appRoute('association-roles'),
	associations: appRoute('associations'),
	home: appRoute('home'),
	security: appRoute('security'),
};


export const SECURITY_BREADCRUMB_DATA = {
	cloneRole:{
		text: 'Clone Roles',
		url: ''
	},
	companyAssociations:{
		text: 'Company Associations',
		url: `security/${SECURITY_ROUTES.companyAssociation.name}`
	},
	companyInheritedAccess:{
		text: 'Company Inherited Access',
		url: `security/${SECURITY_ROUTES.companyInheritedAccess.name}`
	},
	companyRoles:{
		text: 'Company Roles',
		url: `security/${SECURITY_ROUTES.companyRoles.name}`
	},
	companyTypes:{
		text: 'Company Types',
		url: `security/${SECURITY_ROUTES.companyTypes.name}`
	},
	companyCustomerRoles:{
		text: 'Customer Company Roles',
		url: `security/${SECURITY_ROUTES.companyCustomerRoles.name}`
	},
	createCompanyRole:{
		text: 'Create Company Role',
		url: `security/${SECURITY_ROUTES.createCompanyRole.name}`
	},
	createUser:{
		text: 'Create User',
		url: `security/${SECURITY_ROUTES.createUser.name}`
	},
	dashboard : {
		text: 'Roles and Permissions',
		url: SECURITY_ROUTES.securityDashboard.name
	},
	defaultCompanyTypeRoles : {
		text: 'Default Company Type Roles',
		url: `security/${SECURITY_ROUTES.companyTypesRoles.name}`
	},
	manageCompanyAssociationRoles: {
		text: 'Manage Company Association Roles',
		url: ''
	},

	manageCompanyRoles: {
		text: 'Manage Company Roles',
		url: ''
	},
	manageRoleUsers: {
		text: 'Manage Role Users',
		url: ''
	},
	manageUser: {
		text: 'Manage User',
		url: ''
	},
	manageUserCompanyAssociations: {
		text: 'Manage User Associations',
		url: ''
	},
	manageCompanyInheritedAccess: {
		text: 'Manage Company Inherited Access',
		url: ''
	},
	manageCompanyAssociationsAsAccessed: {
		text: 'Manage Accessing Companies',
		url: ''
	},
	manageCompanyAssociationsAsAccessor: {
		text: 'Manage Accessible Companies',
		url: ''
	},
	manageUserRoles: {
		text: 'Manage User Roles',
		url: ''
	},
	manageSystemRole: {
		text: 'Manage System Roles',
		url: ''
	},
	systemRoles:{
		text: 'System Roles',
		url: `security/${SECURITY_ROUTES.systemRoles.name}`
	},
	users:{
		text: 'Users',
		url: `security/${SECURITY_ROUTES.users.name}`
	},
};
